<template>
  <div class="home">
    <p>Home</p>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
    //
  }
}
</script>
